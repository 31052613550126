<template>
  <div id="faq-page">
    <!-- JUMBOTRON -->
    <div class="faq-jumbotron">
      <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base"
           :style="{ backgroundImage: 'url(' + require('@assets/images/pages/pattern01_sm_dark.jpg') + ')' }" >
        <h1 class="mb-1 text-white">¿Tienes dudas?</h1>
        <p class="text-white" v-if="forApplicant">
          Consulta las preguntas y respuestas más frecuentes de nuestros solicitantes dentro de RedGirasol. 
          En caso de que tengas alguna duda que no se encuentre dentro de esta sección contáctanos a través del chat 
          de la plataforma o escribe directamente en whatsapp al 81–2861–9415.
        </p>
        <p class="text-white" v-else>Consulta las preguntas y respuestas más frecuentes dentro de RedGirasol.
          En caso de que tengas alguna duda sobre el estado de tus proyectos e inversiones, consulta el apartado de
          Mis Proyectos o escríbenos directamente en el chat de la plataforma.
        </p>
        <vs-input placeholder="Buscar" v-model="faqSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" icon-no-border />
      </div>
    </div>
    <div class="vx-row" v-if="isMounted">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
        <vx-card>
          <h4>Categorías</h4>
          <ul class="faq-topics mt-4">
            <li v-for="category in categories" :key="category.id" class="p-2 font-medium flex items-center" @click="faqFilter = category.id">
              <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div><span class="cursor-pointer">{{ category.title }}</span>
            </li>
          </ul>

        </vx-card>
      </div>

      <!-- FAQ COL -->
      <template v-if="isMounted">
        <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" v-if="filteredFaq.length > 0">
          <vs-collapse accordion type="margin" class="p-0">
            <vs-collapse-item v-for="(que,index) in filteredFaq" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
              <div slot="header"><h5>{{ que.question }}</h5></div>
              <p style="white-space: pre-line">{{ que.answer }}</p>
            </vs-collapse-item>
          </vs-collapse>
        </div>
        <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" v-else>
          <h5 class="ml-5">No hay resultados</h5>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default{
  name: "Faqs",
  props: {
    audiences: {
      type: Array,
      required: false,
      default: [0]
    },
    useFilter: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data () {
    return {
      faqSearchQuery: '',
      faqFilter: 0,
      allCategoriesId: 0,
      categories: [],
      colors: [ 'primary', 'success', 'danger', 'dark'],
      faqs: [],
      isMounted: false,
    }
  },
  async beforeMount() {
    this.isMounted = false
    this.showLoading(true)
    await this.getFaqsAndCategories();
    this.showLoading(false)
    this.isMounted = true
  },
  computed: {
    filteredFaq () {
      if(!this.isMounted || !this.faqs || this.faqs.length < 1){
        return [];
      }
      return this.faqs.filter((faq) => {
        if(this.faqFilter === this.allCategoriesId){
          return this.filteredQuestionAnswer(faq);
        }
        else {
          return faq.faq_category_id === this.faqFilter && this.filteredQuestionAnswer(faq);
        }
      })
    },
    forApplicant(){
      let result = false;
      if (this.$props.audiences.includes(3)) {
        result = true;
      }
      return result;
    }
  },
  methods: {
    async getFaqsAndCategories(){
      try {
        let catsUrl = 'api/v1/faqCategories';
        let faqsUrl = 'api/v1/faqs';
        if(this.$props.useFilter){
          let params = "type[]=" + this.$props.audiences.join("&type[]=");
          catsUrl += `?${params}`;
          faqsUrl += `?${params}`;
        }
        const resCategories = await axios.get(catsUrl);
        this.categories = this.setCategories(resCategories.data);

        const resFaqs = await axios.get(faqsUrl);
        this.faqs = resFaqs.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    filteredQuestionAnswer(faq){
      return faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(this.faqSearchQuery.toLowerCase())
    },
    setCategories(sourceCategories){
      let cats = [];
      cats.push({id: 0, title: "Todo", color: "grey"});

      let colorIndex = 0;
      sourceCategories.forEach(f => {
        if(colorIndex >= this.colors.length){
          colorIndex = 0;
        }
        cats.push({id: f.id, title: f.title, color: this.colors[colorIndex]});
        colorIndex += 1;
      });
      return cats;
    }
  },
  components: {
  }
}
</script>

<style lang="scss">
#faq-page {
  .faq-jumbotron-content {
    background-repeat: repeat;
  }

  .faq-bg {
    background-color: #fff;
  }
}
</style>
